import Styles from "../styles/grid";
import { FaHandPaper } from "react-icons/fa";
import brbicon from "../../../../assets/brb.png";
import dndicon from "../../../../assets/dnd.png";
import { useSelector, useDispatch } from "react-redux";
import { roomSlice } from "../../store/slice";
import { statusColors } from "../../../../app/helper";
import { useContext } from "react";
import { BaseContext } from "../../../../app/BaseConext";
import { IoCopyOutline } from "react-icons/io5";
import { TbScreenShare } from "react-icons/tb";
import { Tooltip, message } from "antd";
import { copyURL } from "../../../../domain/modules/CopyMeetingUrl";
import { RiSplitCellsHorizontal } from "react-icons/ri";
import { useState } from "react";
import { v4 } from 'uuid';
import { MdOutlineCallToAction } from "react-icons/md";



function SidebarBreakout({ onClickSidebarClose }) {
    const [disabled, setDisabled] = useState(false);
    const [breakoutRooms, setBreakoutRooms] = useState([]);
    const { MyStreams, SocketConnection } = useContext(BaseContext);
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const roomId = useSelector((state) => state.generalSlice.roomId);

    const {
        SidebarInfoBody,
        SidebarInfoBodyHeader,
        SidebarInfoBodyButton,
    } = Styles;


    const handRaisedIds = useSelector(
        ({ generalSlice }) => generalSlice.handRaisedIds
    );
    const roomStatuses = useSelector(
        ({ generalSlice }) => generalSlice.roomStatuses
    );
    const myId = useSelector(
        ({ generalSlice }) => generalSlice.userId
    );
    const isScreenShare = useSelector(
        ({ roomSlice: { uiState } }) => uiState.isScreenShare
    );
    const users = useSelector(({ roomSlice: { users } }) => users);
    const screenShareAllow = useSelector(
        ({ roomSlice: { uiState } }) => uiState.screenShareAllow
    );

    const {
        Sidebar,
        SidebarHeading,
        SidebarHeader,
        SidebarHeaderButton,
        SidebarHR,
        SidebarMemberBody
    } = Styles;

    const rowStyle = (status) => ({
        display: "flex",
        gap: "20px",
        marginLeft: "10px",
        marginRight: "10px",
        marginBottom: "10px",
        padding: "8px",
        borderRadius: "10px",
        alignItems: "center",
        background: "rgb(112, 112, 112)",
        border: selectedStatus === status ? `2px solid ${statusColors[status]}` : "2px solid transparent",
        cursor: "pointer"
    });

    const selectedStatus = useSelector(({ roomSlice }) => {
        return roomSlice.uiState.selectedStatus;
    });

    const createRoom = (roomId) => {
        console.log("--------------");
        if (breakoutRooms.length === 3) {
            return messageApi.open({
                type: "error",
                content: "3 Breaking out rooms allowed",
                duration: 3,
            });
        }
        setDisabled(true); // Disable the button
        setTimeout(() => {
            setDisabled(false)
            setBreakoutRooms([...breakoutRooms, {
                "breakoutRoomId": v4()
            }])
            return messageApi.open({
                type: "success",
                content: "Breaking out new room",
                duration: 3,
            });
        }, 3000);
    }



    return (
        <Sidebar>
            {contextHolder}
            <SidebarHeader>
                <SidebarHeading> Breakout Meeting Rooms </SidebarHeading>
                <SidebarHeaderButton onClick={onClickSidebarClose}>X</SidebarHeaderButton>
            </SidebarHeader>
            <SidebarInfoBody>
                <SidebarInfoBodyButton onClick={() => !disabled && createRoom(roomId)} isDisabled={disabled}>
                    <RiSplitCellsHorizontal size={"18px"} />
                    Create Room
                </SidebarInfoBodyButton>
            </SidebarInfoBody>
            <SidebarHR style={{ marginTop: "20px" }} />
            <SidebarInfoBody>
                {
                    breakoutRooms?.map((room, index) => (
                        <Tooltip key={index} title={room.breakoutRoomId}>
                            <SidebarInfoBodyButton style={{ marginBottom: "5px" }}>
                                <MdOutlineCallToAction size={"18px"} />
                                Breakout Room {index + 1}
                            </SidebarInfoBodyButton>
                        </Tooltip>
                    ))
                }
            </SidebarInfoBody>
        </Sidebar>
    );
}

export default SidebarBreakout;
