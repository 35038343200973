import { useEffect, useState } from "react";
import Styles from "../styles/grid";
import { FaLock } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import "../styles/index.css";
import WorkWsieIcon from "../../../ResultComponent/WorkWsieIcon";


function BottomLeftComponent() {
    const { BottomLeftDiv, Text, Time } = Styles;
    const isMobile = useMediaQuery({ maxWidth: 500 });
    const isSmallMobile = useMediaQuery({ maxWidth: 400 });
    const [date, setDate] = useState('00:00');

    const isLock = useSelector(({ roomSlice: { uiState } }) => uiState.isLock);
    const { userId, adminIds } = useSelector(({ generalSlice }) => generalSlice);
    // const isLock = true;

    useEffect(() => {
        let seconds = 0;
        let interval;

        interval = setInterval(() => {
            seconds++;
            const hours = Math.floor(seconds / 3600).toString().padStart(2, '0');
            const minutes = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
            const remainingSeconds = (seconds % 60).toString().padStart(2, '0');

            let currentTime = `${minutes}:${remainingSeconds}`;
            if (seconds >= 3600) {
                currentTime = `${hours}:${minutes}:${remainingSeconds}`;
            }

            setDate(currentTime);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);


    return (
        <BottomLeftDiv
            style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "auto",
            }}
        >
            {

                isSmallMobile ? (
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
                        {isLock ? (
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                <WorkWsieIcon height="25" width="35" logoOnly={true} />
                                {!adminIds?.includes(userId) && <FaLock size={11} />}
                            </div>
                        ) : (
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <WorkWsieIcon height="20" width="50" logoOnly={true} />
                                <Time >{date}</Time>
                            </div>
                        )}
                    </div>
                ) : (
                    <Text style={{ display: "flex", alignItems: "center" }}>
                        <WorkWsieIcon height="30" width="50" logoOnly={true} />
                        <div style={{ display: "flex", alignItems: "center", whiteSpace: "nowrap" }}>
                            {date}
                            {isLock && <FaLock style={{ marginLeft: "5px" }} size={14} />}
                        </div>
                    </Text>
                )
            }
        </BottomLeftDiv>
    );
}

export default BottomLeftComponent;
