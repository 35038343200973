
import Styles from "../styles/grid";
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import {
    IoSendSharp
} from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { roomSlice } from "../../store/slice";
import { useRef } from "react";
import { useEffect } from "react";
import { formatAMPM } from "../../../../app/helper";
import { useContext } from "react";
import { BaseContext } from "../../../../app/BaseConext";
import SideBarTimerButton from "../modules/SideBarTimerButton";


function SidebarChat({ onClickSidebarClose}) {

    const { SocketConnection } = useContext(BaseContext);
    const chatList = useSelector(({ roomSlice }) => {
        return roomSlice.uiState.chatList;
    });
    const isChatBarClosed = useSelector(({ roomSlice }) => {
        return roomSlice.uiState.isChatBarClosed;
    });
    
    const messages = useRef(null);
    const {
        ChatForm,
        ChatInput,
        ChatButton,
        Sidebar,
        SidebarHeading,
        SidebarHeader,
        SidebarHeaderButton,
        SidebarHR,
        SidebarChatAvatarContainer,
        SidebarMemberBody,
        SidebarChatRow,
        Chat,
        SidebarChatRowInput,
    } = Styles;

    const userId = useSelector(
        ({ generalSlice }) => generalSlice.userId
    );
    const user = useSelector(
        ({ roomSlice }) => roomSlice.users[userId]
    );

    const dispatch = useDispatch();
    
    const handleSubmit = async (e) => {
        const msg = e.target.elements.msg.value;
        e.preventDefault();
        if (msg !== '') {
            const messageData = {
                roomId: await SocketConnection.getRoomId(),
                author: user.email,
                message: msg,
                time: formatAMPM(new Date())
            }

            await SocketConnection.Events({ type: 'SEND_MESSAGE', payload: messageData });

            dispatch(roomSlice.actions.updateChat(messageData));
            e.target.elements.msg.value = ""
        }

    }
    useEffect(() => {
        if (!isChatBarClosed) {
            messages?.current?.scrollIntoView({ behavior: "smooth" });
        }
        // console.log("CHATLIST",chatList[chatList.length - 1 ]);
        // otherMessage?.current?.scrollIntoView({behavior:"smooth"})
    }, [chatList, isChatBarClosed])

    return (
        <Sidebar>
            <SidebarHeader>
                <SidebarHeading> Live Chat </SidebarHeading>
                <SidebarHeaderButton onClick={() => { onClickSidebarClose() }}>X</SidebarHeaderButton>
            </SidebarHeader>
            <SidebarHR style={{ marginTop: "0px" }} />
            <SideBarTimerButton/>
            <SidebarMemberBody style={{ marginBottom: "20%" }} >
                {
                    chatList.map((msgData, Index) => {
                        // console.log("INDEX",chatList[Index].author,chatList[Index].time)
                        // console.log("INDEX - 1",chatList[(Index-1)>=0 ?Index-1 :0].author,chatList[(Index-1)>=0 ?Index-1 :0].time)
                        let hide = false;
                        const newIndex = (Index - 1) >= 0 ? Index - 1 : 0 ;
                        if (chatList[Index].author === chatList[newIndex].author && chatList[Index].time === chatList[newIndex].time) {
                            hide = true
                        }
                        else {
                            hide = false
                        }
                        if (Index - 1 < 0) {
                            hide = false;
                        }

                        // console.log("HIDE", hide)
                        return (
                            <SidebarChatRow key={Index} type={msgData.author === user.email ? "mine" : ''} >
                                <div style={{ display: "flex", flexDirection: "column", rowGap: "5px" }}>
                                    {
                                        !hide &&
                                        <div style={{ display: "flex", flexDirection: msgData.author !== user.email ? "row" : 'row-reverse', columnGap: "5px" }}>
                                            <div style={{ color: "white", fontSize: "12px", textAlign: "end" }}>
                                                {msgData.author === user.email ? "You" : msgData.author}
                                            </div>
                                            <div style={{ color: "white", fontSize: "12px", textAlign: "end" }}>
                                                {msgData.time}
                                            </div>
                                        </div>
                                    }
                                    <div style={{ display: "flex", flexDirection: msgData.author !== user.email ? "row" : 'row-reverse', columnGap: "5px" }}>
                                        <SidebarChatAvatarContainer>
                                            <Avatar size={25} icon={<UserOutlined />}  /*src="https://www.rd.com/wp-content/uploads/2017/09/01-shutterstock_476340928-Irina-Bg.jpg?resize=768,512" */ />
                                        </SidebarChatAvatarContainer>
                                        <Chat>
                                            {msgData.message}
                                        </Chat>
                                    </div>
                                </div>
                            </SidebarChatRow>
                        )
                    })
                }
                <div ref={messages} />

            </SidebarMemberBody>

            <SidebarChatRowInput style={{ marginBottom: "15px" }}>
                <ChatForm onSubmit={handleSubmit}>
                    <ChatInput name="msg" placeholder="Type Message ..." />
                    <ChatButton type='submit'><IoSendSharp size={30} color={"#707070"} /></ChatButton>
                </ChatForm>
            </SidebarChatRowInput>
        </Sidebar >


    );
}


export default SidebarChat;