import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import useSound from "use-sound";
import { LuTimerReset } from "react-icons/lu";
import stopSoundAudio from "../../../../sounds/ding-126626.mp3"
import startSoundAudio from "../../../../sounds/microwave-timer-117077.mp3"


const Timer = () => {

    const [time, setTime] = useState('00:00');
    const timer = useSelector(({ roomSlice: { uiState } }) => uiState.timer);
    const [playStartSound] = useSound(startSoundAudio)
    const [playStopSound] = useSound(stopSoundAudio)

    useEffect(() => {
        let seconds = 0;
        let interval;

        // Play sound when the timer is started
        if (timer) {
            playStartSound()

            interval = setInterval(() => {
                seconds++;
                const hours = Math.floor(seconds / 3600).toString().padStart(2, '0');
                const minutes = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
                const remainingSeconds = (seconds % 60).toString().padStart(2, '0');

                let currentTime = `${minutes}:${remainingSeconds}`;
                if (seconds >= 3600) {
                    currentTime = `${hours}:${minutes}:${remainingSeconds}`;
                }

                setTime(currentTime);
            }, 1000);
        } else {
            setTime("00:00")
            // Play sound when the timer is stopped
            playStopSound()
        }

        return () => {
            clearInterval(interval);
        };
    }, [timer]);

    return timer ?
        <div
            style={{
                width: "100vw",
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bottom: "75px",
                right: "5px",
                zIndex: "2"
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgb(26, 86, 105)",
                    borderRadius: "5px",
                    padding: "5px 10px 5px 10px",
                    gap: "5px",
                    color: "white"
                }}
            >
                <LuTimerReset size={20} style={{ color: "white" }} />
                {time}
            </div>
        </div>
        :
        <></>
}


export default Timer;