import { useContext } from "react";
import { useSelector } from "react-redux";
import { BaseContext } from "../../../../app/BaseConext";
import Styles from "../styles/grid";
import { LuTimerReset } from "react-icons/lu";

const SideBarTimerButton = () => {

    const {
        SidebarHR,
        SidebarInfoBodyButton,
        SidebarInfoBody
    } = Styles;

    const { SocketConnection } = useContext(BaseContext
    );
    const adminIds = useSelector(
        ({ generalSlice }) => generalSlice.adminIds
    );
    const timer = useSelector(
        ({ roomSlice: { uiState } }) => uiState.timer
    );
    const myId = useSelector(
        ({ generalSlice }) => generalSlice.userId
    );
    const roomId = useSelector((state) => state.generalSlice.roomId);
    const handleTimerClick = () => {
        SocketConnection.Events({
            type: 'TIMER', payload: {
                roomId,
                timer: !timer
            }
        });
    }
    return adminIds?.includes(myId) ?
        <>
            <SidebarInfoBody>
                <h3 style={{ color: "white" }}>Timer</h3>
                <SidebarInfoBodyButton
                    onClick={handleTimerClick}
                    style={
                        timer ?
                            { border: "1px solid red" } :
                            { border: "1px solid gray" }
                    }
                >
                    <LuTimerReset size={20} style={{ color: "white" }} />
                    {
                        timer ? "Stop " : "Start "
                    }
                    Timer
                </SidebarInfoBodyButton>
            </SidebarInfoBody>
            <SidebarHR style={{ marginTop: "20px" }} />
        </> :
        <></>
}

export default SideBarTimerButton;